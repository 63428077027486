<template >
  <div id="Store">
    <div class="title">
      <div class="titleText">{{ titleJudge }}</div>
    </div>
    <div class="contentBlock">
      <div class="storeTabs" v-if="!isCreateMode">
        <div
          class="storeTab"
          v-for="(it, index) in tabList"
          :class="isActiveTab(it)"
          :key="index"
          @click="tabClickHandler(it)"
          >{{ it.label }}</div
        >
      </div>
      <router-view :shopInfo="shopInfo" @checkCreateMode="checkCreateMode"></router-view>
    </div>
  </div>
</template>
<script>
import { getRouteByName } from '@/utils/route'
export default {
  data() {
    return {
      shopInfo: {},
      isCreateMode: true,
    }
  },
  watch: {
    $route(to, from) {
      // 當已經在商店頁面時,點了側欄我的商店 還是會重新導回'商店簡介'
      if (to.name === 'MemberStore') {
        if (this.isCreateMode) {
          this.$router.replace({ name: 'MemberStoreCreateStore' })
        } else {
          this.$router.replace({ name: 'MemberStoreStoreIntro' })
        }
      }
    },
  },
  created() {
    this.checkCreateMode()
  },
  computed: {
    titleJudge() {
      if (this.$route.name === 'MemberStoreCreateStore') {
        return '创建商店'
      } else {
        return '我的商店'
      }
    },
    tabList() {
      return getRouteByName('Member')
        .children.find((it) => it.name === 'MemberStore')
        .children.slice(1)
        .map((it) => ({ label: it.meta.context, name: it.name }))
    },
  },
  methods: {
    isActiveTab(it) {
      if (it.name === this.$route.name) {
        return 'active'
      } else {
        return ''
      }
    },
    tabClickHandler(it) {
      this.$router.push({ name: it.name })
    },
    async checkCreateMode() {
      this.$store.commit('setLoading', true)
      await this.$store
        .dispatch('shop/getMyShop')
        .then((res) => {
          // 進入編輯商店頁面
          this.isCreateMode = false

          // 如果曾經創建過商店,則直接導入到商店介紹頁面
          if (this.$route.name === 'MemberStore' && !this.isCreateMode) {
            this.$router.replace({ name: 'MemberStoreStoreIntro' })
          }

          if (res.data.length == 0) {
            this.shopInfo = {}
          } else {
            this.shopInfo = res.data
          }
        })
        .catch((err) => {
          if (err?.message === '无此商店') {
            // 進入創立商店頁面
            if (this.$route.name !== 'MemberStoreCreateStore') {
              this.$router.replace({ name: 'MemberStoreCreateStore' })
            }
            this.isCreateMode = true
          }
        })
      this.$store.commit('setLoading', false)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/views/Member/Store/Store.scss';
#Store {
  width: 100%;
  .title {
    width: 100%;
    height: 62px;
    background: transparent linear-gradient(180deg, #aad4ff 0%, #52a8ff 10%, #52a8ff 100%) 0% 0%
      no-repeat padding-box;
    border: 1px solid #52a8ff;
    border-radius: 5px 5px 0px 0px;
    display: flex;
    align-items: center;
    .titleText {
      margin-left: 20px;
      font-size: 1.375rem;
      color: #ffffff;
    }
  }
  .contentBlock {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    padding: 20px 26px;
    .storeTabs {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
      grid-gap: 15px 30px;
      border-bottom: 1.5px $borderLineColor solid;
      padding-bottom: 20px;
      .storeTab {
        border: 1px solid #005fde;
        color: #005fde;
        background-color: #ffffff;
        box-shadow: 0px 3px 6px #539bfc33;
        border: 1px solid #005fde;
        color: #005fde;
        border-radius: 30px;
        font-size: 1.125rem;
        font-weight: bold;
        padding: 8px;
        cursor: pointer;
        &.active,
        &:hover {
          color: #ffffff;
          background: transparent linear-gradient(180deg, #579fff 0%, #3481e8 100%) 0% 0% no-repeat
            padding-box;
        }
      }
    }
  }
}
</style>
